<template>
    <modal ref="modalCedis" titulo="Cedis" tamano="modal-lg" no-aceptar adicional="Guardar" @adicional="$refs.modalCedis.toggle();">
        <div class="row mx-0 justify-center">
            <div class="col-7 mb-3">
                <p class="text-general f-15">
                    Seleccionar los cedis donde se mostrará esta <br /> pagina de inicio
                </p>
            </div>
            <div class="col-7 mb-3">
                <small class="pl-3 text-general">
                    Añadir cedis
                </small>
                <el-select v-model="value" size="small" class="w-100" @change="agregarCedisALanding">
                    <el-option
                    v-for="item in cedisDisponibles"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div v-for="(data, index) in cedis" :key="index" class="col-7 d-middle mb-3">
                <span class="text-general f-16">
                    {{ data.nombre }}
                </span>
                <i class="icon-delete-trash f-18 text-general ml-auto cr-pointer" @click="eliminarCedis(data.id)" />
            </div>
        </div>
    </modal>
</template>

<script>
import Landing from '~/services/landing/landing_admin'
export default {
    props: {
        cedis: {
            type: Array,
            required: true
        }
    },
    data(){
        return {
            value: '',
            cedisDisponibles: [],
        }
    },
    computed:{
        idLanding(){
            return Number(this.$route.params.idLanding)
        }
    },
    methods: {
        toggle(){
            this.listaCedisDisponible();
            this.value = ''
            this.$refs.modalCedis.toggle();
        },
        async listaCedisDisponible(){
            try {
                const { data } = await Landing.getListaCedisDisponibles(this.idLanding)
                this.cedisDisponibles = data.cedis
            } catch (error){
                return this.error_catch(error)
            }
        },
        async agregarCedisALanding(item){
            try {
                const payload = {
                    id_landing : this.idLanding,
                    id_cedis: item
                }
                const { data } = await Landing.agregarCedisALanding(payload)
                this.$emit('actualizado')
                this.notificacion('Éxito', data.mensaje, 'success')
                // this.$refs.modalCedis.toggle();

            } catch (error){
                return this.error_catch(error)
            }
        },
        async eliminarCedis(item){
            try {
                const { data } = await Landing.eliminarCedisDeLanding(this.idLanding, item)
                this.$emit('actualizado')
                this.notificacion('Éxito', data.mensaje, 'success')
            } catch (error){
                return this.error_catch(error)
            }
        }
    }
}
</script>
